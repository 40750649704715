import Vue from 'vue';
import { IfacConferencesHelpers } from '@ifac/ui';

export const gridDefaultColDef = {
  filter: 'agSetColumnFilter',
  resizable: true,
  sortable: true,
};

export const gridColumns = [
  {
    headerName: 'Conference Name',
    field: 'title',
    minWidth: 400,
    sort: 'asc',
    valueGetter: ({ data: { conference } }) => IfacConferencesHelpers.getConferenceName(conference),
  },
  {
    headerName: 'Schedule',
    field: 'startsAt',
    flex: 1,
    minWidth: 200,
    valueGetter: ({ data: { conference } }) => {
      const startsAt = Vue.filter('formatDate')(conference.startsAt);
      const endsAt = Vue.filter('formatDate')(conference.endsAt);
      return `${startsAt} - ${endsAt}`;
    },
  },
  {
    headerName: 'Location',
    field: 'city',
    maxWidth: 200,
    valueGetter: ({ data: { conference } }) => `${conference?.city} - ${conference?.country?.name}`,
  },
  {
    headerName: 'Sponsoring TCs',
    field: 'title',
    minWidth: 200,
    valueGetter: ({ data: { conference } }) => {
      const tcs = [];
      conference.tcs.forEach((tc) => {
        tcs.push(`${tc.ccNumber}.${tc.number}`);
      });
      return tcs.join(', ');
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    maxWidth: 150,
    valueGetter: ({ data }) => Vue.filter('ucfirst')(data.status),
  },
];
