<template>
  <div class="container-fluid full-width-page-wrapper">
    <div class="row">
      <div class="col my-auto">
        <h3 class="page-title">My Approvals</h3>
      </div>
    </div>
    <tabs
      :tabItems="TAB_ITEMS"
      defaultTab="active">
      <template slot="tab-content">
        <ag-grid-vue
          class="ag-theme-alpine"
          :pagination="true"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          :gridOptions="gridOptions"
          rowSelection="single"
          domLayout="autoHeight"
          :paginationPageSize="paginationPageSize"
          @cellClicked="onCellClicked"
          @gridReady="onGridReady"
        ></ag-grid-vue>
      </template>
    </tabs>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import Approvals from '@/services/Api/Approvals';
import GridMixin from '@/views/components/grids/GridMixin';
import {
  gridColumns,
  gridDefaultColDef,
} from '@/views/components/grids/approvals/ApprovalsGrid';
import { ConferenceStatuses } from '@ifac/ui';
import { ConferenceSectionTabs } from '@/services/tabs';
import Tabs from '@/views/components/Tabs.vue';

export default {
  name: 'Approvals',
  components: {
    Tabs,
    AgGridVue,
  },
  created() {
    this.TAB_ITEMS = ConferenceSectionTabs;
  },
  mixins: [GridMixin],
  data() {
    return {
      rowData: [],
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
      paginationPageSize: 15,
    };
  },
  computed: {
    section() {
      return this.$route.query.section;
    },
    active() {
      const { released } = ConferenceStatuses;
      return [released];
    },
    historic() {
      const {
        approved,
        published,
        completed,
      } = ConferenceStatuses;
      return [approved, published, completed];
    },
    statuses() {
      const statuses = {
        active: this.active,
        historic: this.historic,
      };
      return statuses[this.section];
    },
  },
  methods: {
    matchStatus(status) {
      return this.statuses.find((s) => s.id === status);
    },
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;

      try {
        const { data } = await Approvals.get(1, 9999);
        this.rowData = data.data.items
          ?.filter(({ conference }) => this.matchStatus(conference?.status));
      } finally {
        this.refreshing = false;
      }
    },
    onCellClicked({ data }) {
      this.$router.push({
        name: 'ApprovalsEdit',
        params: {
          approvalId: data.id,
          conferenceId: data.conference.id,
        },
      });
    },
  },
  watch: {
    section() {
      this.refresh();
    },
  },
};
</script>
